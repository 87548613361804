import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import API_URL from "../../config/config";

// Css File
import '../../css/footer.css'

// Icons & images common files
import * as Img from '../../components/Img';
import Icons from '../../components/icons';

const Footer = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [email, setEmail] = useState("");  // Store the email entered by user
    const [message, setMessage] = useState("");  // Store the success/error message
    const [loading, setLoading] = useState(false); // Track loading state

    const handleClick = (index) => {
        setActiveIndex(index);
    };

    const validateEmail = (email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

    const handleSubscribe = async () => {
        if (!email) {
            setMessage("Please enter a valid email.");
            return;
        }
        if (!validateEmail(email)) {
            setMessage("Please enter a valid email address.");
            return;
        }


        setLoading(true);  // Set loading to true while the request is being made

        try {
            const response = await fetch(`${API_URL}/post-subscribe.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    cmail: email,  // Send the email in the request body
                }),
            });

            const data = await response.json();

            if (data.error === 0) {
                setMessage("Thank you for subscribing!");
                setEmail("");  // Clear email input after successful subscription
            } else {
                setMessage("Failed to subscribe. Please try again.");
            }
        } catch (error) {
            console.error('Error during subscription:', error);
            setMessage("An error occurred. Please try again.");
        } finally {
            setLoading(false);  // Reset loading state
        }
    };

    return (
        <div className='footer-section padding-50'>
            <div className='container-lg'>
                <div className='footer-section-inner'>
                    <div className='footer-top'>
                        <div className='footer-left'>
                            <div className='custom-heading ch-medium'>Sign up for newsletter</div>
                            <div className='custom-text ct-small'>
                                Sign up now and be the first to know about exclusive offers, latest fashion news & style tips!
                            </div>
                        </div>
                        {/* <div className='footer-right'>
                            <ul>
                                <li><Link><img src={Img.Twitter} alt='twitter' /></Link></li>
                                <li><Link><img src={Img.Instagram} alt='instagram' /></Link></li>
                                <li><Link><img src={Img.YouTube} alt='youtube' /></Link></li>
                                <li><Link><img src={Img.LinkedIn} alt='linkdin' /></Link></li>
                            </ul>
                        </div> */}
                    </div>

                    <div className='footer-center'>
                        <div className='custom-input'>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className='form-control'
                                placeholder='Enter your Email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}  // Update email on input change
                            />
                            <button
                                className='common-btn-item cbi-solid'
                                onClick={handleSubscribe} // Trigger subscription when button is clicked
                                disabled={loading}
                            >
                                {loading ? "Subscribing..." : <><Icons.EnvelopeSvg /> Subscribe</>}
                            </button>
                        </div>
                        {message && <div className="message mt-2" style={{ color: 'white' }}>{message}</div>}  {/* Display message */}
                    </div>

                    <div className='footer-bottom'>
                        {/* <ul className='pb-2'>
                            {[{ to: "/", label: "Home" },
                            { to: "/About-us", label: "About us" },
                            { to: "/Contact-us", label: "Contact Us" },
                            { to: "/Privacy-policy", label: "Privacy Policy" },
                            { to: "/Terms-condition", label: "Term & Condition" },
                            { to: "/Sitemap", label: "Sitemap" }]
                                .map((item, index) => (
                                    <li
                                        key={index}
                                        className={activeIndex === index ? 'active' : ''}
                                        onClick={() => handleClick(index)}
                                    >
                                        <Link to={item.to}>{item.label}</Link>
                                        {index < 6 && <span>|</span>}
                                    </li>
                                ))}
                        </ul> */}

                        {/* <div className='footer-copyright'>
                            <div className='custom-heading ch-small'>
                                © 2024 All Rights Reserved
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
