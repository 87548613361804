import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Sidebar from '../../routers/Sidebar';
import * as Img from '../../components/Img';
import "../../css/header.css";
import Icons from '../../components/icons';
import API_URL from "../../config/config"; // Import API config
import Commoninformation from '../Pagehome/Commoninformation';

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState([]); // State to store categories
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Fetch categories from API
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${API_URL}get-category-title.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({}) // Add any necessary body parameters if required
        });
        const data = await response.json();
        if (data.error === 0) {
          setCategories(data.categories); // Assuming categories array in response
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    document.body.style.overflow = isSidebarOpen ? 'hidden' : '';
  }, [isSidebarOpen]);

  const handleSearchClick = () => {
    if (searchTerm.trim()) {
      navigate(`/search/${encodeURIComponent(searchTerm.trim())}`);
      setSearchTerm('');
      setIsModalOpen(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOutsideClick = (e) => {
    if (e.target.className === 'search-modal') {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Commoninformation />
      <div className={`main-nevigation ${isSticky ? 'sticky' : ''}`}>
      <header className={`main-header ${isSticky ? 'shrink' : ''}`}>
        <div className='mh-inner'>
          <div className='mh-left  d-none'>
            <div className={`app-container ${isSidebarOpen ? 'overlay' : ''}`}>
              {isSidebarOpen && <div className="overlay-background" onClick={closeSidebar}></div>}
              {/* <div className='menubox-icon' onClick={toggleSidebar}>
                <span></span>
                <span></span>
                <span></span>
              </div> */}
              <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            </div>
          </div>
          <div className='mh-center'>
            <Link to="/">
              <img src={Img.logo} alt="Logo" className={`${isSticky ? 'small-logo' : ''}`} />
            </Link>
          </div>
          <div className='mh-right d-none'>
            <div className='mhr-inner'>
              <div className="mh-search-bar">
                <div className="icon" onClick={() => setIsModalOpen(true)}>
                  <Icons.MagnifyingglassSvg />
                </div>
              </div>
              <div className='common-btn d-none'>Sign in</div>
            </div>

            {isModalOpen && (
              <div className="search-modal" onClick={handleCloseModal}>
                <div className="modal-content zoom-in">
                  <div className='custom-heading white justify-content-center'>Forbes</div>
                  <span className="close" onClick={handleCloseModal}><Icons.XmarkSvg /></span>
                  <input
                    type="text"
                    className="search-input"
                    placeholder="Search here..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleSearchClick()}
                  />
                  <span className='search-pointer' onClick={handleSearchClick}>
                    <Icons.ArrowrightSvg />
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </header>

      {/* Dynamic Category List */}
      <div className='category-header'>
        <div className='cni-center'>
          <div className='categoary_section'>
            <ul>
              {categories.map((category) => (
                <li key={category.id}>
                  <Link
                    to={`/category/${encodeURIComponent(category.title.toLowerCase())}`}
                    className='link'
                  >
                    {category.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Header;
